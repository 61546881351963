/**
 * Copyright Motili Inc., 2016 All Rights Reserved
 */

import pjson from '../package.json';

const WP_ENVIRONMENT = process.env.REACT_APP_ENV || 'local';
const VERSION = `${pjson.version}.${pjson.sprint}.${
    pjson.build === '0' ? Date.now() : pjson.build
}`;
const RELEASE = pjson.version;
const STORAGE_NAMESPACE = 'motili';
const SENTRY = {
    dsn: 'https://6ba23886c40048f9bf2b496cec7adc99@sentry.io/282123',
};
const ROUTING = {
    opsBaseRoute: '/ops',
    cliBaseRoute: '/cli',
    conBaseRoute: '/con',
    toolsBaseRoute: '/tools',
};

const COLOR = {
    darkNavy: '#0e2248',
    darkNavyFaded: '#17335d80',
    navy: '#17335d',
    motiliLightBlue: '#57c1e9',
    motiliLightBlueFaded: '#1976d214',
    motiliDarkBlue: '#3f82a5',
    duskySky: '#357bb8',
    blue: '#2196F3',
    teal: '#01BCD4',
    green: '#15b039',
    white: '#FFFFFF',
    black: '#000000',
    orange: '#fc6d17',
    red: '#e63232',
    mapMarkerYellow: '#ffee00',
    mapMarkerRed: '#59BBE1',
    yellow: '#fcb817',
    darkGray: '#3b3b3b',
    mediumGray: '#818181',
    lightGray: '#e9e9e9',
    purple: '#673AB7',
    fadedGray: '#f5f5f5',
    inputBorderGray: '#cccccc',
    darkCharcoal: '#333333',
    arrowHover: '#666666',
    dataGridCellBorder: '#81818157',
};

const PRODUCT_SEARCH_FUSE_OPTIONS = {
    // id: 'id',
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    includeScore: true,
    includeMatches: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['display', 'details'],
};
const MODAL_PRODUCT_SEARCH_FUSE_OPTIONS = PRODUCT_SEARCH_FUSE_OPTIONS;
MODAL_PRODUCT_SEARCH_FUSE_OPTIONS.keys = ['sku', 'display', 'details'];
const MANAGED_PRICING_SEARCH_FUSE_OPTIONS = {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['product.sku', 'product.details', 'product.display'],
};
const MANAGED_VENDOR_SEARCH_FUSE_OPTIONS = {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['vendorBranchName', 'vendorBranchNumber'],
};
const SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS = {
    tokenize: true,
    matchAllTokens: true,
    shouldSort: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    threshold: 0.4,
    keys: ['tradeTypeId', 'display', 'attributes'],
};
const BULK_CREATION = {
    maximum: 500,
};

const STATES = {
    states: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
    ],
};

const BRANCHES = [
    { display: 'Air Force' },
    { display: 'Army' },
    { display: 'Navy' },
];
const REGIONS = [
    { display: 'Region-1' },
    { display: 'Region-2' },
    { display: 'Region-3' },
    { display: 'Region-4' },
    { display: 'Region-5' },
    { display: 'Region-6' },
    { display: 'Region-7' },
    { display: 'Region-8' },
    { display: 'Region-9' },
    { display: 'Region-10' },
];
const SHOW_TERRITORY_CLIENTS = ['BALFOUR BEATTY'];

const DEFAULT_ASSET_TAGGING_FEE = {
    REPLACE: {
        adjustedPrice: 0,
        adjustedPriceIncludingTax: 0,
        price: 0,
        priceAdjustment: 0,
        priceIncludingTax: 0,
        adjustedPayout: 0,
    },
};

const INVOICE_REMIT_TO = {
    name: 'Motili, Inc.',
    address: 'PO Box 655028',
    city: 'Dallas',
    state: 'TX',
    zipCode: '75265-5028',
    country: 'United States',
    email: '',
};

const LIFTGATE_SKU = 'MHVC-RES-SHPFRT-SHPLFGT';

const DEV_FIREBASE = {
    app: {
        apiKey: 'AIzaSyCrQXT17Hvn7fxSnvLxZNRc5dofDS_Xu8c',
        authDomain: 'dashboard-dev-4925e.firebaseapp.com',
        projectId: 'dashboard-dev-4925e',
        storageBucket: 'dashboard-dev-4925e.appspot.com',
        messagingSenderId: '1033458571124',
        appId: '1:1033458571124:web:f64b18d19441a9f8033e21',
        measurementId: 'G-N6T4S1E71Z',
    },
    messaging: {
        vapidKey:
            'BP1Q9LUUyIwc0oWQLXcpyP8LKsSdhJnWgJfLL0Owel6935T6eZUzjHUm_dsi88fXMIOE93Qh58-HLvCGIKnATt8',
    },
};
const PROD_FIREBASE = {
    app: {
        apiKey: 'AIzaSyClL4zaARO5bfBmT2BrTYIejc0T1IXu8wo',
        authDomain: 'dashboard-prod-4d8b1.firebaseapp.com',
        projectId: 'dashboard-prod-4d8b1',
        storageBucket: 'dashboard-prod-4d8b1.appspot.com',
        messagingSenderId: '874252978903',
        appId: '1:874252978903:web:8bb28a751681a73b41fe92',
        measurementId: 'G-D9348Q5R1D',
    },
    messaging: {
        vapidKey:
            'BHyiAzAZLdSjRhl1GbmzQUH9p_tslAgLcK_lfb3_zMZohNZDb8nnXMN6XNQsWpzPhI0Dycben-EGNnYGqNr0jgE',
    },
};
const FIREBASE_CONFIG = {
    prod: PROD_FIREBASE,
    dev: DEV_FIREBASE,
};

const NOT_APPLICABLE_PHOTO = {
    url: 'https://res.cloudinary.com/motili/image/upload/v1620143124/assets/primary/motili-primary-default.png',
    name: 'motili-primary-default.png',
    type: 'image/png',
    defaultDescription:
        'Photo Requirement unavailable. Requirement does not pertain to scope of work.',
    phash: 'e93df4fec00903d4',
};

const GOOGLE_MAPS_API_KEY = 'AIzaSyBTDm7KbK3Uyfq1F1_41nfeIXZN_cPCVaU';

const config = {
    local: {
        pimServiceUrl: 'https://api.qa2.motilitest.com/pim-service/pim',
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        dashboardMfUrl: 'http://localhost:4000',
        apiBaseUrl: 'http://localhost:3000/api',
        mossApiBaseUrl: 'http://localhost:3060/api/moss',
        motiliNotificationServiceApiBaseUrl: 'localhost:3010',
        requirementsBuilderApi: 'http://localhost:3001/rb/api',
        clientJobMicrositeUrl: 'http://localhost:3300',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'http://localhost:4100',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
            ],
            micrositeContactUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
                'https://client-status.dev2.motilidev.com/',
            ],
        },
    },
    dashboardlocal: {
        // MF - For Dashboard project only
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motilidevintg.com/api',
        dashboardMfUrl: 'http://localhost:4000',
        mossApiBaseUrl: 'http://localhost:3060/api/moss',
        motiliNotificationServiceApiBaseUrl: 'localhost:3010',
        requirementsBuilderApi: 'http://localhost:3001/rb/api',
        clientJobMicrositeUrl: 'http://localhost:3300',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'http://localhost:4100',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
            ],
            micrositeContactUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
                'https://client-status.dev2.motilidev.com/',
            ],
        },
    },
    dev: {
        pimServiceUrl: 'https://api.dev2.motilidev.com/pim-service/pim',
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://api.slalom-dev.motili.link/api',
        dashboardMfUrl: 'https://dashboard.motilidev.com',
        mossApiBaseUrl: 'https://s2.motilidev.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'https://ns.motilidev.com',
        clientJobMicrositeUrl: 'https://client-status.dev2.motilidev.com',
        requirementsBuilderApi: 'https://api.slalom-dev.motili.link/api/rb',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'https://api.dev2.motilidev.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
            ],
            micrositeContactUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
                'https://client-status.dev2.motilidev.com/',
            ],
        },
    },
    dashboarddevintg: {
        // MF - For Dashboard project only(MicroFrontend)
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motilidevintg.com/api',
        dashboardMfUrl: 'https://dashboard.motilidevintg.com',
        mossApiBaseUrl: 'https://s2.motilidev.com/api/moss',
        motiliNotificationServiceApiBaseUrl: 'https://ns.motilidev.com',
        clientJobMicrositeUrl: 'https://client-status.dev2.motilidev.com',
        requirementsBuilderApi: 'https://api.slalom-dev.motili.link/api/rb',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'https://api.dev2.motilidev.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
            ],
            micrositeContactUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
                'https://client-status.dev2.motilidev.com/',
            ],
        },
    },
    dev2: {
        pimServiceUrl: 'https://api.dev2.motilidev.com/pim-service/pim',
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://api2.motilidev.com/api',
        dashboardMfUrl: 'https://dashboard.dev2.motilidev.com',
        mossApiBaseUrl: 'https://onsite-v2.motilidev.com/api/moss',
        motiliNotificationServiceApiBaseUrl: 'https://ns.motilidev.com',
        clientJobMicrositeUrl: 'https://client-status.dev2.motilidev.com',
        requirementsBuilderApi: 'https://api.dev2.motilidev.com/rb/api',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'https://api.dev2.motilidev.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
            ],
            micrositeContactUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
                'https://client-status.dev2.motilidev.com/',
            ],
        },
    },
    dev2cs: {
        pimServiceUrl: 'https://api.dev2.motilidev.com/pim-service/pim',
        googleAnalyticsID: 'UA-154690269-1',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://sandbox-api.motilidev.com/api',
        dashboardMfUrl: 'https://dashboard.motilidev.com',
        mossApiBaseUrl: 'https://sandbox-api.motilidev.com/api/moss/',
        clientJobMicrositeUrl: 'NO URL!',
        requirementsBuilderApi: 'NO URL!',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'https://api.dev2.motilidev.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilidev.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            uiInterval: 5, // minute
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'dev2cs-motili-files',
                    identityPoolId:
                        'us-west-2:32b7f8c5-cea7-4a57-8fb5-2c71aff6a6e5',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
            ],
            micrositeContactUrl: [
                'https://m2.motilidev.com/',
                'https://m.airandheatdoneright-dev.com/',
                'https://client-status.dev2.motilidev.com/',
            ],
        },
    },
    qa: {
        pimServiceUrl: 'https://api.qa2.motilitest.com/pim-service/pim',
        googleAnalyticsID: 'UA-154690269-2',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motilitest.com/api',
        dashboardMfUrl: 'https://dashboard.qa2.motilitest.com',
        mossApiBaseUrl: 'https://onsite-v2.motilitest.com/api/moss',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        clientJobMicrositeUrl: 'https://client-status.qa2.motilitest.com',
        requirementsBuilderApi: 'https://api.qa2.motilitest.com/rb/api',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'https://api.qa2.motilitest.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilitest.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'qa-motili-files',
                    identityPoolId:
                        'us-west-2:4b17ee30-43d0-49a6-ae09-b399188a2ca0',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m.motilitest.com/',
                'https://m.airandheatdoneright-test.com/',
            ],
            micrositeContactUrl: [
                'https://m.motilitest.com/',
                'https://m.airandheatdoneright-test.com/',
                'https://client-status.qa2.motilitest.com/',
            ],
        },
    },
    stage: {
        pimServiceUrl: 'https://api.staging2.motilistage.com/pim-service/pim',
        googleAnalyticsID: 'UA-154690269-3',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motilistage.com/api',
        dashboardMfUrl: 'https://dashboard.staging2.motilistage.com',
        mossApiBaseUrl: 'https://onsite-v2.motilistage.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        clientJobMicrositeUrl: 'https://client-status.staging2.motilistage.com',
        requirementsBuilderApi: 'https://api.staging2.motilistage.com/rb/api',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl:
            'https://api.staging2.motilistage.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motilistage.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motilistage.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motilistage.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motilistage.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'stage-motili-files',
                    identityPoolId:
                        'us-west-2:87636d0f-6673-4782-83a4-b873c769be48',
                    region: 'us-west-2',
                },
            },
        },
        firebase: DEV_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m.motilistage.com/',
                'https://m.airandheatdoneright-stage.com/',
            ],
            micrositeContactUrl: [
                'https://m.motilistage.com/',
                'https://m.airandheatdoneright-stage.com/',
                'https://client-status.staging2.motilistage.com/',
            ],
        },
    },
    prod: {
        pimServiceUrl: 'https://api.prod2.motili.com/pim-service/pim',
        amplitudeApiKey: 'e57eae21d993873c30d1df1c9cd53360',
        googleAnalyticsID: 'UA-64678121-12',
        environment: WP_ENVIRONMENT,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: 'https://s2.motili.com/api',
        dashboardMfUrl: 'https://dashboard.prod2.motili.com',
        mossApiBaseUrl: 'https://onsite-v2.motili.com/api/moss/',
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        clientJobMicrositeUrl: 'https://client-status.prod2.motili.com',
        requirementsBuilderApi: 'https://api.prod2.motili.com/rb/api',
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: 'https://api.prod2.motili.com/sched-service',
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: 'assets.motili.com/tag/',
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: 'https://help.motili.com/Content/Home.htm',
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: 'https://help.motili.com/Content/Accept%20a%20Job/Quick%20Start.htm',
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: 'https://help.motili.com/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm',
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: 'prod-motili-files',
                    identityPoolId:
                        'us-west-2:09a211d4-e03f-4046-be15-ff2d4b351dd8',
                    region: 'us-west-2',
                },
            },
        },
        firebase: PROD_FIREBASE,
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        microsite: {
            micrositeUrl: [
                'https://m.motili.com/',
                'https://m.airandheatdoneright.com/',
            ],
            micrositeContactUrl: [
                'https://m.motili.com/',
                'https://m.airandheatdoneright.com/',
                'https://client-status.prod2.motili.com/',
            ],
        },
    },
};

let docker_config;
if (process.env.REACT_APP_ENV === 'docker') {
    await fetch('/env.json')
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Work with the data
            docker_config = data;
        })
        .catch(error => {
            // Handle any errors that occurred during the fetch
            console.error('Fetch error:', error);
        });

    config.docker = {
        googleAnalyticsID: docker_config.googleAnalyticsID,
        environment: docker_config.environment,
        release: RELEASE,
        version: VERSION,
        apiBaseUrl: docker_config.apiBaseUrl,
        dashboardMfUrl: docker_config.dashboardMfUrl,
        mossApiBaseUrl: docker_config.mossApiBaseUrl,
        motiliNotificationServiceApiBaseUrl: 'NO URL!',
        clientJobMicrositeUrl: docker_config.clientJobMicrositeUrl,
        requirementsBuilderApi: docker_config.requirementsBuilderApi,
        baseCDNURL: 'https://static.motili.com/',
        schedulerApiBaseUrl: docker_config.schedulerApiBaseUrl,
        pimServiceUrl: docker_config.pimServiceUrl,
        storageNameSpace: STORAGE_NAMESPACE,
        sentry: SENTRY,
        color: COLOR,
        routing: ROUTING,
        productSearchFuseOptions: PRODUCT_SEARCH_FUSE_OPTIONS,
        modalProductSearchFuseOptions: MODAL_PRODUCT_SEARCH_FUSE_OPTIONS,
        managedPricingSearchFuseOptions: MANAGED_PRICING_SEARCH_FUSE_OPTIONS,
        managedVendorSearchFuseOptions: MANAGED_VENDOR_SEARCH_FUSE_OPTIONS,
        systemAssetConfigurationSearchFuseOptions:
            SYSTEM_ASSET_CONFIGURATION_SEARCH_FUSE_OPTIONS,
        autoSyncFinance: true,
        bulkCreation: BULK_CREATION,
        states: STATES,
        baseQrCodeUrl: docker_config.baseQrCodeUrl,
        helpLinks: [
            {
                title: 'Help Center',
                text: 'Visit the Motili Help Center',
                href: `${docker_config.helpBaseUrl}/Content/Home.htm`,
            },
            {
                title: 'Quick Start',
                text: 'Motili Quick Start Guide',
                href: `${docker_config.helpBaseUrl}/Content/Accept%20a%20Job/Quick%20Start.htm`,
            },
            {
                title: 'Submit a Work Order',
                text: 'How to Submit a Work Order',
                href: `${docker_config.helpBaseUrl}/Content/Use%20the%20Contractor%20Platform/Complete%20a%20Work%20Order/Submit%20the%20Work%20Order.htm`,
            },
        ],
        tokenValidation: {
            backendInterval: 5, // minute
            timeBeforeExpired: 5, // minute
        },
        territory: {
            branches: BRANCHES,
            regions: REGIONS,
        },
        showTerritoryClients: SHOW_TERRITORY_CLIENTS,
        defaultAssetTaggingFee: DEFAULT_ASSET_TAGGING_FEE,
        defaultShippingSearchWebsite:
            'https://www.parcelmonitor.com/track-united-states//',
        invoiceRemitTo: INVOICE_REMIT_TO,
        liftGateSku: LIFTGATE_SKU,
        aws: {
            s3: {
                motiliFiles: {
                    bucketName: docker_config.motiliFilesBucketName,
                    identityPoolId: docker_config.identityPoolId,
                    region: docker_config.region,
                },
            },
        },
        firebase: FIREBASE_CONFIG[docker_config.firebaseConfig],
        notApplicablePhoto: NOT_APPLICABLE_PHOTO,
        microsite: {
            micrositeUrl: docker_config.micrositeUrl,
            micrositeContactUrl: docker_config.micrositeContractUrl,
        },
    };
}

export default config[WP_ENVIRONMENT];

/**
 * @license
 * @copyright Copyright Motili Inc., 2024 All Rights Reserved
 */

import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import unionBy from 'lodash/unionBy';
import remove from 'lodash/remove';

import {
    AccountRoleAPI,
    ContractorServiceTypeAPI,
    ContractorStatusAPI,
    FinancialStatusAPI,
    OrderNotificationTypeAPI,
    OrderReturnCancelReasonApi,
    OrderReturnReasonApi,
    OrderReturnStatusApi,
    QuoteIssueAPI,
} from 'common/stores/redux/MetaStore/MetaStoreApi.types';
import * as ApiService from './ApiService';
import { getContractorId } from './AuthService';

export function timezones() {
    const query = {
        filter: {
            where: {
                or: [{ country: 'US' }, { country: 'CA' }],
            },
            order: ['display ASC'],
        },
    };
    return ApiService.get(true, 'TimeZones', query, false);
}

export function countries() {
    return ApiService.get(true, 'Countries', {}, false);
}

export function currencies() {
    return ApiService.get(true, 'Currencies', {}, false);
}

export function states() {
    return ApiService.get(true, 'States', {}, false);
}

export function usStates() {
    const query = {
        filter: {
            fields: ['id', 'display', 'counties'],
            order: ['display ASC'],
        },
    };
    return ApiService.get(true, 'UsStates', query, false);
}

/**
 * gets list of motili operations accounts
 * caution - motili_* only
 */
export function opsAccounts() {
    return ApiService.get(true, 'accounts', {
        filter: {
            where: {
                active: true,
                userType: 'motili',
            },
            order: ['firstName ASC', 'lastName ASC'],
            fields: [
                'id',
                'firstName',
                'lastName',
                'fullName',
                'department',
                'email',
                'departmentAssignment',
            ],
            include: ['roles'],
        },
    });
}

export function accountPreferenceTypes() {
    return ApiService.get(
        true,
        'accounts/preference-types',
        undefined,
        false
    ).then(preferenceTypes => sortBy(preferenceTypes, ['display']));
}

export function accountRoles() {
    const query = {
        filter: {
            fields: ['name', 'description'],
        },
    };
    return ApiService.get(true, 'Roles', query, false).then(roles =>
        roles.map((r: AccountRoleAPI) => ({
            id: r.name,
            name: r.name,
            display: r.description,
            description: r.description,
        }))
    );
}

export function accountRoleTypes() {
    return ApiService.get(true, 'Accounts/roleTypes');
}

export function accountStatuses() {
    return ApiService.get(true, 'Accounts/statuses', undefined, false);
}

export function assetStatuses() {
    return ApiService.get(true, 'Assets/statuses', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function assetPhotoTypes() {
    return ApiService.get(true, 'Assets/photoTypes', undefined, false).then(
        photoTypes => sortBy(photoTypes, ['display'])
    );
}

export function assetRetirementReasons() {
    return ApiService.get(true, 'Assets/retirementReasons', undefined, false);
}

export function assetConfigurationCategories() {
    return ApiService.get(
        true,
        'AssetConfigurations/categories',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function assetUntaggableReasons() {
    return ApiService.get(true, 'Assets/untaggableReasons', undefined, false);
}

export function assetRecommendationTypes() {
    return ApiService.get(true, 'Assets/recommendationTypes', undefined, false);
}

export function assetRepairReasons() {
    return ApiService.get(true, 'Assets/repairReasons', undefined, false);
}

export function documentTypes() {
    return ApiService.get(true, 'Documents/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function documentTradeTypes() {
    return ApiService.get(true, 'WorkOrders/tradeTypes', undefined, false)
        .then(tradeTypes => sortBy(tradeTypes, ['userRelevance']))
        .then(tradeTypes =>
            tradeTypes.map(tradeType => ({
                ...tradeType,
                tradeSubTypes: sortBy(tradeType.tradeSubTypes, [
                    'userRelevance',
                ]),
            }))
        );
}

export function workOrderPhotoTypes() {
    return ApiService.get(true, 'WorkOrders/photoTypes', undefined, false).then(
        photoTypes => sortBy(photoTypes, ['display'])
    );
}

export function workOrderPriorityTypes() {
    return ApiService.get(true, 'WorkOrders/priorities', undefined, false).then(
        priorityTypes => orderBy(priorityTypes, 'completionTime', 'desc')
    );
}

export function workOrderContactTypes() {
    return ApiService.get(
        true,
        'WorkOrders/contactTypes',
        undefined,
        false
    ).then(contactTypes => sortBy(contactTypes, ['display']));
}

export function orderFulfillmentTypes() {
    return ApiService.get(
        true,
        'Orders/fulfillmentTypes',
        undefined,
        false
    ).then(fulfillmentTypes => sortBy(fulfillmentTypes, ['display']));
}
export function orderHoldReasons() {
    return ApiService.get(true, 'Orders/holdReasons', undefined, false).then(
        holdReasons => sortBy(holdReasons, ['display'])
    );
}

export function folderAttachmentTypes() {
    return ApiService.get(
        true,
        'Folders/attachmentTypes',
        undefined,
        false
    ).then(attachmentTypes => sortBy(attachmentTypes, ['display']));
}

export function propertyAttachmentTypes() {
    return ApiService.get(
        true,
        'Folders/propertyAttachmentTypes',
        undefined,
        false
    ).then(attachmentTypes => sortBy(attachmentTypes, ['display']));
}

export function projectAttachmentTypes() {
    return ApiService.get(
        true,
        'Folders/projectAttachmentTypes',
        undefined,
        false
    ).then(attachmentTypes => sortBy(attachmentTypes, ['display']));
}

export async function warrantyContactTypes() {
    const contactTypes = await ApiService.get(
        true,
        'Warranties/contactTypes',
        undefined,
        false
    );
    return sortBy(contactTypes, ['display']);
}

export function warrantyOwnerTypes() {
    return ApiService.get(true, 'Warranties/ownerTypes', undefined, false).then(
        ownerTypes => sortBy(ownerTypes, ['sortOrder'])
    );
}

export function warrantyOwnerInstallTypes() {
    return ApiService.get(
        true,
        'Warranties/ownerInstallTypes',
        undefined,
        false
    ).then(ownerInstallTypes => sortBy(ownerInstallTypes, ['display']));
}

export function warrantyRegistrationStatuses() {
    return ApiService.get(
        true,
        'Warranties/registrationStatuses',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

export function workOrderStatuses() {
    return ApiService.get(true, 'WorkOrders/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function workOrderTypes() {
    return ApiService.get(true, 'WorkOrders/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function workOrderPdfTypes() {
    return ApiService.get(true, 'WorkOrders/pdfTypes', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function workOrderContactPreferenceTypes() {
    return ApiService.get(
        true,
        'WorkOrders/workOrderContactPreferenceTypes',
        undefined,
        false
    );
}

export function workOrderRequirementApprovalReasons() {
    return ApiService.get(
        true,
        'WorkOrders/photoRequirementApprovalReasons',
        undefined,
        false
    );
}

export function quoteIssues() {
    return ApiService.get(true, 'Quotes/issues', undefined, false).then(
        (statuses: QuoteIssueAPI[]) => sortBy(statuses, ['display'])
    );
}

export function quoteStatuses() {
    return ApiService.get(true, 'Quotes/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function quoteTypes() {
    return ApiService.get(true, 'Quotes/types', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

export function quoteReasons() {
    return ApiService.get(true, 'Quotes/reasons', undefined, false);
}

export function quoteReopenReasons() {
    return ApiService.get(true, 'Quotes/reopenReasons', undefined, false);
}

export function quoteRequestRevisionTypes() {
    return ApiService.get(
        true,
        'Quotes/requestRevisionTypes',
        undefined,
        false
    );
}

export function quoteRejectTypes() {
    return ApiService.get(true, 'Quotes/rejectTypes', undefined, false);
}

export function quotePdfTypes() {
    return ApiService.get(true, 'Quotes/quotePdfTypes', undefined, false).then(
        pdfTypes => sortBy(pdfTypes, ['display'])
    );
}

export function orderStatuses() {
    return ApiService.get(true, 'Orders/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function orderIssues() {
    return ApiService.get(true, 'Orders/issues', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function workOrderIssues() {
    return ApiService.get(true, 'WorkOrders/issues', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function workOrderIssueRejectedTypes() {
    return ApiService.get(
        true,
        'WorkOrders/issueRejectedTypes',
        undefined,
        false
    ).then(statuses => sortBy(statuses, ['display']));
}

export function orderTypes() {
    return ApiService.get(true, 'Orders/types', undefined, false).then(types =>
        sortBy(types, ['display'])
    );
}

export function orderFulfillmentMethods() {
    return ApiService.get(
        true,
        'Orders/fulfillmentMethods',
        undefined,
        false
    ).then(fulfillmentMethods => sortBy(fulfillmentMethods, ['display']));
}

export function orderCancelReasons() {
    return ApiService.get(true, 'Orders/cancelReasons', undefined, false);
}

export function orderContactTypes() {
    return ApiService.get(true, 'Orders/contactTypes', undefined, false).then(
        contactTypes => sortBy(contactTypes, ['display'])
    );
}

export function draftStatuses() {
    return ApiService.get(true, 'Drafts/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function orderNotificationTypes() {
    return ApiService.get(
        true,
        'Orders/notificationTypes',
        undefined,
        false
    ).then(types => sortBy<OrderNotificationTypeAPI>(types, ['display']));
}

export function orderLogisticsTypes() {
    return ApiService.get(true, 'Orders/logisticsTypes', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export async function orderReturnStatuses() {
    const statuses = await ApiService.get(
        true,
        'Orders/orderReturnStatuses',
        undefined,
        false
    );
    return buildListWithOther<OrderReturnStatusApi>(
        statuses,
        ['OTHER'],
        ['display']
    );
}

export async function orderReturnReasons() {
    const reasons = await ApiService.get(
        true,
        'Orders/orderReturnReasons',
        undefined,
        false
    );
    return buildListWithOther<OrderReturnReasonApi>(
        reasons,
        ['OTHER'],
        ['display']
    );
}

export async function orderReturnCancelReasons() {
    const reasons = await ApiService.get(
        true,
        'Orders/orderReturnCancelReasons',
        undefined,
        false
    );
    return buildListWithOther<OrderReturnCancelReasonApi>(
        reasons,
        ['OTHER'],
        ['display']
    );
}

export function accountDepartmentTypes() {
    return ApiService.get(
        true,
        'Accounts/departmentTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function accountDepartmentAssignmentTypes() {
    return ApiService.get(
        true,
        'Accounts/departmentAssignmentTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function accountTypes() {
    return ApiService.get(true, 'Accounts/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function financialTerms() {
    return ApiService.get(true, 'Terms/terms', undefined, false)
        .then(types => sortBy(types, ['display']))
        .then(types => {
            const _types = [...types];
            const sortByDateTypes = remove(_types, type =>
                type.display.startsWith('Net ')
            );
            return [..._types, ...sortBy(sortByDateTypes, ['days'])];
        });
}

export function financialBillingStatuses() {
    return ApiService.get(true, 'Billings/statuses', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function financialPaymentStatuses() {
    return ApiService.get(true, 'Payments/statuses', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export async function financialStatuses() {
    const _billingStatuses: FinancialStatusAPI[] = await ApiService.get(
        true,
        'Billings/statuses',
        undefined,
        false
    );
    const _paymentStatuses: FinancialStatusAPI[] = await ApiService.get(
        true,
        'Payments/statuses',
        undefined,
        false
    );
    return sortBy(unionBy(_billingStatuses, _paymentStatuses, 'display'), [
        'display',
    ]);
}

export async function financialInvoiceLineOfBusinesses() {
    const _billingInvoiceLineOfBusinesses = await ApiService.get(
        true,
        'Billings/invoiceLineOfBusinesses',
        undefined,
        false
    );
    const _paymentInvoiceLineOfBusinesses = await ApiService.get(
        true,
        'Payments/invoiceLineOfBusinesses',
        undefined,
        false
    );
    return {
        billing: _billingInvoiceLineOfBusinesses
            ? _billingInvoiceLineOfBusinesses.sort()
            : [],
        payment: _paymentInvoiceLineOfBusinesses
            ? _paymentInvoiceLineOfBusinesses.sort()
            : [],
    };
}
export async function financialSalesOrderLineOfBusinesses() {
    const _billingSalesOrderLineOfBusinesses = await ApiService.get(
        true,
        'Billings/salesOrderLineOfBusinesses',
        undefined,
        false
    );
    const _mergeLOB = ['Sales Order - Integrated', 'Sales Order - eCommerce'];

    return {
        billing: _billingSalesOrderLineOfBusinesses
            ? _billingSalesOrderLineOfBusinesses.sort()
            : [],
        billingMerge: _billingSalesOrderLineOfBusinesses
            ? filter(_billingSalesOrderLineOfBusinesses, it =>
                  _mergeLOB.includes(it)
              ).sort()
            : [],
    };
}

/**
 * gets list of clients for client drop down lists
 * caution - motili_* only
 * @returns {Promise<Object>}
 */
export function clients() {
    return ApiService.get(true, 'Clients', {
        filter: {
            where: {
                statusId: {
                    inq: ['APPROVED', 'ENABLED'],
                },
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'statusId',
                'website',
                'addressId',
                'operationInCountry',
                'currency',
            ],
            include: [
                {
                    relation: 'address',
                    scope: {
                        fields: [
                            'stdDeliveryLine',
                            'stdLastLine',
                            'standardized',
                            'rawAddress',
                        ],
                    },
                },
                {
                    relation: 'settings',
                    scope: {
                        fields: ['settingTypeId', 'setting'],
                        where: {
                            settingTypeId: 'LINE_OF_BUSINESS',
                        },
                    },
                },
                {
                    relation: 'contractorOrderingAccount',
                    scope: {
                        fields: ['id'],
                    },
                },
            ],
        },
    });
}

export function clientStatuses() {
    return ApiService.get(true, 'Clients/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function clientSettings() {
    return ApiService.get(true, 'Clients/settingTypes', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function clientFreightTypes() {
    return ApiService.get(true, 'Clients/freightTypes', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function clientIntegrationTypes() {
    return ApiService.get(
        true,
        'Clients/integrationTypes',
        undefined,
        false
    ).then(integrations => sortBy(integrations, ['display']));
}

export function clientStatusChangedNotificationConfig() {
    return ApiService.get(
        true,
        'Clients/statusChangedNotificationConfig',
        undefined,
        false
    );
}

export function clientBusinessChannels() {
    return ApiService.get(
        true,
        'Clients/businessChannels',
        undefined,
        false
    ).then(channels => sortBy(channels, ['display']));
}

export function clientOrderIssues() {
    return ApiService.get(true, 'Clients/orderIssues', undefined, false).then(
        issues => sortBy(issues, ['display'])
    );
}

export function contractorQualificationTypes() {
    return ApiService.get(
        true,
        'Contractors/qualificationTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function contractorFileTypes() {
    return ApiService.get(true, 'Contractors/fileTypes', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function contractorTeamMemberNotifications() {
    return ApiService.get(
        true,
        'Contractors/teamMemberNotifications',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function contractorTeamMemberTitles() {
    return ApiService.get(
        true,
        'ContractorTeams/titles',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function contractorTeamMemberFileTypes() {
    return ApiService.get(
        true,
        'ContractorTeams/fileTypes',
        undefined,
        false
    ).then(types => sortBy(types, ['display']));
}

export function contractorStatuses() {
    return ApiService.get(true, 'Contractors/statuses', undefined, false).then(
        (statuses: ContractorStatusAPI) => sortBy(statuses, ['display'])
    );
}

export async function contractorServiceTypes() {
    const serviceTypes: ContractorServiceTypeAPI = await ApiService.get(
        true,
        'Contractors/serviceTypes',
        undefined,
        false
    );
    // Sorts serviceTypes alphabetically by keys, sortBy function loses keys so invalid here
    return Object.keys(serviceTypes)
        .sort()
        .reduce<ContractorServiceTypeAPI>((obj, key) => {
            const _obj = obj;
            _obj[key as keyof ContractorServiceTypeAPI] =
                serviceTypes[key as keyof ContractorServiceTypeAPI];
            return obj;
        }, {} as ContractorServiceTypeAPI);
}
export function contractorCredentials() {
    return ApiService.get(true, 'Contractors/credentials', undefined, false);
}
export function contractorExternalCertifications() {
    return ApiService.get(
        true,
        'Contractors/externalCertifications',
        undefined,
        false
    );
}

export function contractorInternalCertifications() {
    return ApiService.get(
        true,
        'Contractors/internalCertifications',
        undefined,
        false
    );
}

export function contractorClients() {
    const contractorId = getContractorId();
    return ApiService.get(
        true,
        `Contractors/${contractorId}/clients`,
        undefined,
        false
    );
}

export function contractorBlocklistReasons() {
    return ApiService.get(
        true,
        'Contractors/blocklistReasons',
        undefined,
        false
    );
}

export function contractorJobTypeEligibilityTypes() {
    return ApiService.get(
        true,
        'Contractors/jobTypeEligibilityTypes',
        undefined,
        false
    );
}
export function directedInvoiceTypes() {
    return ApiService.get(
        true,
        'ContractorClientDirectSupplies/directedInvoiceTypes',
        undefined,
        false
    );
}

export function productCategories() {
    return ApiService.get(true, 'products/categories')
        .then(categories => sortBy(categories, ['display']))
        .then(categories =>
            categories.map(category => ({
                ...category,
                subCategories: sortBy(category.subCategories, ['display']),
            }))
        );
}

export function managedProductCategories() {
    return ApiService.get(true, 'managedProducts/categories')
        .then(categories => sortBy(categories, ['display']))
        .then(categories =>
            categories.map(category => ({
                ...category,
                subCategories: sortBy(category.subCategories, ['display']),
            }))
        );
}

export function adjustmentTypes() {
    return ApiService.get(
        true,
        'Utilities/adjustmentTypes',
        undefined,
        false
    ).then(adjustmentTypes => sortBy(adjustmentTypes, ['display']));
}

export function lineItemAdjustmentReasonTypes() {
    return ApiService.get(
        true,
        'Utilities/lineItemAdjustmentReasonTypes',
        undefined,
        false
    ).then(adjustmentReasonTypes => sortBy(adjustmentReasonTypes, ['display']));
}

export function properties() {
    return ApiService.get(true, 'Properties', {
        filter: {
            where: {
                active: true,
            },
            order: ['rawAddress ASC'],
            fields: ['id', 'name', 'rawAddress'],
        },
    });
}

export function propertyTypes() {
    return ApiService.get(true, 'Properties/types', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function propertyStatuses() {
    return ApiService.get(true, 'Properties/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function productAttributeTypes() {
    return ApiService.get(true, 'products/attributeTypes')
        .then(attributes => sortBy(attributes, ['display']))
        .then(attributes =>
            attributes.map(attribute => ({
                ...attribute,
                subTypes: sortBy(attribute.subTypes, ['display']),
            }))
        );
}

export function managedProductAttributeTypes() {
    return ApiService.get(true, 'managedProducts/attributeTypes')
        .then(attributes => sortBy(attributes, ['display']))
        .then(attributes =>
            attributes.map(attribute => ({
                ...attribute,
                subTypes: sortBy(attribute.subTypes, ['display']),
            }))
        );
}

export function managedProductPricingTypes() {
    return ApiService.get(true, 'managedpricing/types').then(types =>
        sortBy(types, 'display')
    );
}

export function unitOfMeasures() {
    return ApiService.get(true, 'managedProducts/unitOfMeasures').then(res =>
        sortBy(res, ['display'])
    );
}

export function vendorsApproved() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            where: {
                statusId: 'APPROVED',
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
                'operationInCountry',
                'currency',
                'typeId',
            ],
        },
    });
}

export function vendorsAll() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

export async function projects() {
    const _project = await ApiService.get(true, 'Projects', undefined, false);
    return sortBy(_project, ['name']);
}
export function projectContactTypes() {
    return ApiService.get(true, 'Projects/contactTypes', undefined, false);
}

export function projectHoldReasonTypes() {
    return ApiService.get(true, 'Projects/holdReasonTypes', undefined, false);
}

export function projectStatuses() {
    return ApiService.get(true, 'Projects/statuses', undefined, false).then(
        statuses => sortBy(statuses, ['display'])
    );
}

export function projectTypes() {
    return ApiService.get(true, 'Projects/types', undefined, false).then(
        types => types
    );
}

export function projectScopeTypes() {
    return ApiService.get(true, 'Projects/scopeTypes', undefined, false).then(
        types => types
    );
}

export function vendorManufacturers() {
    return ApiService.get(true, 'Vendors', {
        filter: {
            where: {
                active: true,
                typeId: 'MANUFACTURER',
            },
            order: ['name ASC'],
            fields: [
                'id',
                'name',
                'doingBusinessAs',
                'active',
                'website',
                'addressId',
            ],
        },
    });
}

export function ticketProviders() {
    return ApiService.get(true, 'Tickets/providers', undefined, false).then(
        types => sortBy(types, ['display'])
    );
}

export function managedVendorDistributionTypes() {
    return ApiService.get(
        true,
        'ManagedVendors/distribution/types',
        undefined,
        false
    ).then(configurations => configurations);
}

export function managedVendorIntegrationTypes() {
    return ApiService.get(
        true,
        'ManagedVendors/integration/types',
        undefined,
        false
    ).then(_integrationTypes => _integrationTypes);
}

export function managedVendorStatuses() {
    return ApiService.get(
        true,
        'ManagedVendors/statuses',
        undefined,
        false
    ).then(statuses => statuses);
}

export function managedVendorTypes() {
    return ApiService.get(
        true,
        'ManagedVendors/vendorTypes',
        undefined,
        false
    ).then(types => types);
}

export function templateTypes() {
    return ApiService.get(true, 'Templates/types', undefined, false);
}

/**
 * Build list and move other(given otherIds) to last index.
 *
 */
function buildListWithOther<T>(
    list: Array<{ id: string } & T>,
    otherIds: string[],
    sortKeys: string[]
) {
    if (otherIds && otherIds.length > 0) {
        let others = list.filter(s => {
            return otherIds.find(id => {
                return id === s.id;
            });
        });
        if (others) {
            others = sortBy(others, sortKeys);
            let returnList = list.filter(s => {
                return !otherIds.find(id => {
                    return id === s.id;
                });
            });
            returnList = sortBy(returnList, sortKeys);
            returnList = returnList.concat(others);
            return returnList;
        }
        return sortBy(list, sortKeys);
    }
    return sortBy(list, sortKeys);
}

export function financialSyncQueueStatuses() {
    return ApiService.get(true, 'financeSyncQueues/statuses', undefined, false)
        .then(types => sortBy(types, ['display']))
        .catch(() => []);
}

export function financialSyncQueueModels() {
    return ApiService.get(true, 'financeSyncQueues/models', undefined, false)
        .then(types => sortBy(types, ['display']))
        .catch(() => []);
}

export function financialSyncQueueResolveReasons() {
    return ApiService.get(
        true,
        'financeSyncQueues/resolveReasons',
        undefined,
        false
    )
        .then(types => sortBy(types, ['display']))
        .catch(() => []);
}

export function lhsRiskTypes() {
    return ApiService.get(true, 'WorkOrders/lhsRiskTypes', undefined, false);
}

export function tsmRegions() {
    return ApiService.get(true, `TSMManagements/tsmRegions`, {}, false);
}

export function workOrderReassignReasons() {
    return ApiService.get(
        true,
        'WorkOrders/contractorReassignReasons',
        undefined,
        false
    );
}

export function workOrderCheckInCheckOutReasons() {
    return ApiService.get(
        true,
        'WorkOrders/checkInCheckOutReasons',
        undefined,
        false
    );
}

import { createSlice } from '@reduxjs/toolkit';
import { DistributionCentersandInventoryViewState } from 'common/types/distributionCenterAndInventory';

const defaultState: DistributionCentersandInventoryViewState = {
    initialized: false,
    searchFilters: [],
    distinctVendors: [],
    states: [],
    selectedStates: [],
    selectedDistinctVendors: [],
    polygon: null,
    zipcode: '',
    showInventory: false,
    distributionCenterForInventory: {},
    inventoryResults: [],
    searchType: {
        id: 'LOCATION',
        display: 'Location',
    },
    searchText: '',
};

export const distributionCentersAndInventorySlice = createSlice({
    name: 'distributionCentersAndInventory',
    initialState: defaultState,
    reducers: {
        resetFilters: () => {
            return defaultState;
        },
        resetAdvancedFilters: (state, action) => {
            const { distinctVendors, states } = action.payload;
            return {
                ...state,
                polygon: null,
                zipcode: '',
                showInventory: false,
                selectedStates: states,
                selectedDistinctVendors: distinctVendors,
            };
        },
        resetSearchFilters: state => {
            return {
                ...state,
                searchFilters: [],
                inventoryResults: [],
            };
        },
        initializeFilters: (state, action) => {
            const { initialized, distinctVendors, states } = action.payload;
            return {
                ...state,
                initialized,
                selectedDistinctVendors: distinctVendors,
                selectedStates: states,
            };
        },
        loadSavedView: (state, action) => {
            const { selectedState } = action.payload;
            return {
                ...state,
                ...selectedState,
            };
        },
        setDistinctVendors: (state, action) => {
            return {
                ...state,
                distinctVendors: action.payload,
            };
        },
        setStates: (state, action) => {
            return {
                ...state,
                states: action.payload,
            };
        },
        setSearchFilters: (state, action) => {
            return {
                ...state,
                searchFilters: action.payload,
            };
        },
        setSelectedStates: (state, action) => {
            return {
                ...state,
                selectedStates: action.payload,
            };
        },
        setSelectedDistinctVendors: (state, action) => {
            return {
                ...state,
                selectedDistinctVendors: action.payload,
            };
        },
        setZipcode: (state, action) => {
            if (action.payload.length === 0) {
                return {
                    ...state,
                    zipcode: '',
                };
            }
            return {
                ...state,
                zipcode: action.payload,
            };
        },
        setShowInventory: (state, action) => {
            return {
                ...state,
                showInventory: action.payload,
            };
        },
        setDistributionCenterForInventory: (state, action) => {
            return {
                ...state,
                distributionCenterForInventory: action.payload,
            };
        },
        setInventoryResults: (state, action) => {
            return {
                ...state,
                inventoryResults: action.payload,
            };
        },
        setSearchType: (state, action) => {
            return {
                ...state,
                searchType: action.payload,
            };
        },
        setSearchText: (state, action) => {
            return {
                ...state,
                searchText: action.payload,
            };
        },
        setPolygon: (state, action) => {
            return {
                ...state,
                polygon: action.payload,
            };
        },
    },
});

export const {
    resetFilters,
    resetAdvancedFilters,
    resetSearchFilters,
    initializeFilters,
    loadSavedView,
    setDistinctVendors,
    setStates,
    setSearchFilters,
    setSelectedStates,
    setSelectedDistinctVendors,
    setPolygon,
    setZipcode,
    setShowInventory,
    setDistributionCenterForInventory,
    setInventoryResults,
    setSearchText,
    setSearchType,
} = distributionCentersAndInventorySlice.actions;

export default distributionCentersAndInventorySlice.reducer;
